<template>
  <div class="card--project card--unit" v-if="unit" @click="openUnitDetail">
    <!--    <div class="top&#45;&#45;part">-->
    <!--      <card-project-image-->
    <!--        :photos="photos"-->
    <!--        :primaryPhoto="primaryPhoto"-->
    <!--        :allowMultiImage="allowMultiImage"-->
    <!--        :useRouterLink="false"-->
    <!--        :imageAlt="unitName"-->
    <!--      ></card-project-image>-->
    <!--    </div>-->
    <div>
      <div class="bottom--part">
        <h3 class="name multiline-ellipsis-2">
          {{ unitName }}
        </h3>
        <div class="size">
          <span class="mr-4">LB {{ unit.building_size }} m2</span>
          <span>LT {{ unit.land_size }} m2</span>
        </div>
        <div class="label--container">
          <div class="d-flex">
            <div class="label--room ml-2" v-if="unit.floor">
              <v-icon class="mr-2" v-if="unit.property_type === propertyTypeEnum.HOUSE"
                >mdi-stairs</v-icon
              >
              <v-icon class="mr-2" v-else-if="unit.property_type === propertyTypeEnum.APARTMENT"
                >mdi-stairs-box</v-icon
              >
              {{ unit.floor }}
            </div>
          </div>
        </div>
        <div class="price--after">
          <span>{{ unit.price_str }}</span>
        </div>
        <div class="direction" v-if="unit.building_direction">
          <span>{{ $t('general.direction') }}: </span>
          <span>{{ unit.building_direction }} </span>
        </div>
        <div class="fixed--bottom-margin"></div>
        <div class="fixed--bottom">
          <div class="d-flex">
            <div class="label--room">
              <v-icon>mdi-bed</v-icon>
              <div>{{ unit.bedroom }}</div>
            </div>
            <div class="label--room">
              <v-icon>mdi-shower-head</v-icon>
              <div>{{ unit.bathroom }}</div>
            </div>
          </div>
          <!--          <div class="available">-->
          <!--            <span>{{ $t('general.available') }} </span>-->
          <!--            <span>{{ unitType.total_available_units }} </span>-->
          <!--            <span>{{ $t('general.unit') }} </span>-->
          <!--          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import CardProjectImage from '@/components/utils/partials/card-project-image';
import HelperMixin from '@/mixins/helpers';
import { mapState } from 'vuex';

export default {
  mixins: [HelperMixin],
  components: {
    // CardProjectImage,
  },
  props: {
    unit: {
      default: null,
      require: true,
    },
    premier: {
      default: false,
    },
    showCompare: {
      default: true,
    },
    allowMultiImage: {
      default: false,
    },
  },
  data: () => ({
    debounceCompare: null,
  }),
  computed: {
    ...mapState({
      propertyTypeEnum: (state) => state.v2.masters.propertyTypeEnum,
    }),
    photos() {
      return [
        {
          url: this.unit.photo,
        },
      ];
    },
    primaryPhoto() {
      return {
        url: this.unit.photo,
      };
    },
    unitName() {
      let unitName = '';
      if (this.unit.unit_block) {
        unitName += this.$t('general.unitBlock') + ' ' + this.unit.unit_block;
      } else if (this.unit.unit_category) {
        unitName += this.unit.unit_category + ' ';
      }
      unitName += ' ' + this.$t('general.unitNumber') + ' ' + this.unit.unit_number;
      return unitName;
    },
  },
  filters: {
    lowerCase(e) {
      return e.toLowerCase();
    },
  },
  methods: {
    openUnitDetail() {
      this.$modal.show('modal-unit-detail', { unit: this.unit });
    },
  },
};
</script>
